* { 
  margin: 0; 
  padding: 0; 
  box-sizing: border-box; 
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url("../public/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins-Bold"),
    url("../public/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Light";
  src: local("Poppins-Light"),
    url("../public/fonts/Poppins/Poppins-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Medium";
  src: local("Poppins-Medium"),
    url("../public/fonts/Poppins/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: local("Poppins-SemiBold"),
    url("../public/fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
}

body {
  font-family: "Poppins";
  background-color: #fff;
}

body.dark {
  background-color: #18191A;
}

body.hidden-scroll  {
  overflow: hidden;
}

button {
  background-color: unset;
  border: unset;
}

.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
